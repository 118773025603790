import React from 'react';
import { motion } from 'framer-motion';

const PuzzlePiece = ({ piece, isSelected, onSelect }) => {
    const pieceStyle = {
        backgroundImage: `url(${piece.image})`,
       //backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/wheeloffortune--boa.appspot.com/o/floue.png?alt=media&token=765631dc-f6a0-4284-a9f4-3b02c067d8d1")', // Définissez l'image de fond ici
       // backgroundImage: `url(${process.env.PUBLIC_URL}/Images/puzzle1/piece_${piece.id}.jpg)`, 
        backgroundSize: 'cover',
        width: '100px', 
        height: '100px',
        position: 'absolute', // important pour le positionnement lors du drag and drop
        left: piece.x, // position x de la pièce
        top: piece.y, // position y de la pièce
    };
    
    const shakeAnimation = {
        selected: {
            scale: 1.5,
            rotateY: [0, 360, 360],
            rotateX: [ 360, 360, 0],
            rotateZ: [ 360, 0, 360],
            transition: {
                rotateY: {
                    duration: 10,
                    loop: Infinity, // Faire l'animation aller-retour indéfiniment
                    ease: "easeInOut"
                },
                rotateX: {
                    duration: 10,
                    loop: Infinity, // Faire l'animation aller-retour indéfiniment
                    ease: "easeInOut"
                },
                rotateZ: {
                    duration: 10,
                    loop: Infinity, // Faire l'animation aller-retour indéfiniment
                    ease: "easeInOut"
                }
            }
        },
        unselected: {
            scale: 1,
            rotateY: 0,
            rotateX: 0,
            rotateZ: 0,
            transition: {
                duration: 0.5
            }
        }
    };
    

    return (
        <motion.div
        style={pieceStyle}
        variants={shakeAnimation}
        animate={isSelected ? 'selected' : 'unselected'}
        onClick={() => onSelect(piece.id)}
    >
        {/* Contenu de la pièce */}
    </motion.div>
        
    );
};

export default PuzzlePiece;