class GameLogic {
    constructor(puzzleModel, updatePiecesStateCallback) {
        this.puzzleModel = puzzleModel;
        this.selectedPieces = [];
        this.updatePiecesState = updatePiecesStateCallback; // Callback pour mettre à jour l'état dans React
    }

    onPieceDrop(pieceId, x, y) {
        const targetPiece = this.puzzleModel.findPieceAt(x, y);
        if (targetPiece && targetPiece.id !== pieceId) {
            this.puzzleModel.exchangePieces(pieceId, targetPiece.id);
        }
    
        // Vérifier si le puzzle est résolu
        if (this.puzzleModel.isSolved()) {
            console.log("Félicitations ! Le puzzle est résolu.");
        }
    }

    selectPiece(pieceId) {
        if (this.selectedPieces.includes(pieceId)) {
            // Si la pièce est déjà sélectionnée, la désélectionner
            this.selectedPieces = this.selectedPieces.filter(id => id !== pieceId);
        } else {
            // Ajouter la pièce à la sélection
            this.selectedPieces.push(pieceId);

            // Si deux pièces sont sélectionnées, les échanger
            if (this.selectedPieces.length === 2) {
                this.puzzleModel.exchangePieces(this.selectedPieces[0], this.selectedPieces[1]);
                this.selectedPieces = []; // Réinitialiser la sélection
                // Retirer le console.log d'ici, car il sera géré dans le composant
            }
        
    } 
    console.log(`Pièces sélectionnées actuelles : ${this.selectedPieces}`);
}

isPuzzleSolved() {
    return this.puzzleModel.isSolved();
}
    

    // ... autres méthodes
    
   
    
    


    
    

    
    

    

    // Vous pouvez ajouter ici d'autres méthodes liées à la logique du jeu si nécessaire
}

export default GameLogic;
