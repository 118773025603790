import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDs-uD5zmmmLLrsYGA8XC1OojPNqS6oZts",
    authDomain: "puzzle-35821.firebaseapp.com",
    projectId: "puzzle-35821",
    storageBucket: "puzzle-35821.appspot.com",
    messagingSenderId: "226900339163",
    appId: "1:226900339163:web:06918847043d987ee05ffe",
    measurementId: "G-9S2ZKT8K0G"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;