import React, { useState, useEffect } from 'react';
import PuzzlePiece from '../View/PuzzlePiece';
import PuzzleModel from '../Model/PuzzleModel';
import GameLogic from '../Model/GameLogic';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Button } from "@nextui-org/react";
import { Link } from "react-router-dom";
import Question from '../Components/Question';
import { getAuth,signOut } from "firebase/auth";


const PuzzleBoard = () => {
    

    const imageUrls = [
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_0.jpg?alt=media&token=9d26d69c-a1a3-47c1-9826-678f70e1daa6",
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_1.jpg?alt=media&token=504bef1c-e7d0-4db7-8760-a4c8032ffcc2",
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_2.jpg?alt=media&token=08a5a07d-462b-4825-8d35-b855c7e3fe81",
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_3.jpg?alt=media&token=aadec087-6b7b-4086-a428-8dc4d70ae9c0",
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_4.jpg?alt=media&token=c572165b-0e0a-41c7-af69-8ae49f533f4e",
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_5.jpg?alt=media&token=1230d72e-6b09-4b46-a13e-742e6d84b470",
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_6.jpg?alt=media&token=c79256a8-b27b-4f0b-b1f4-195b494d539c",
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_7.jpg?alt=media&token=b2e9c526-3fed-46e6-86b8-0e6555d8270d",
        "https://firebasestorage.googleapis.com/v0/b/puzzle-35821.appspot.com/o/Images%2Fpuzzle1%2Fpiece_8.jpg?alt=media&token=95787288-7b7b-4e94-ae87-e4b93dc36ebb",
    ];


    const [puzzleModel] = useState(() => new PuzzleModel(3, imageUrls)); 
    const [timeLeft, setTimeLeft] = useState(60);    // Exemple pour un puzzle 3x3
    
    //const [gameLogic] = useState(new GameLogic(puzzleModel));
    const [gameLogic, setGameLogic] = useState();
    const [pieces, setPieces] = useState([]);
    //const [pieces, setPieces] = useState([...puzzleModel.puzzle]);
    const [selectedPieceIds, setSelectedPieceIds] = useState([]);
    const [isSolved, setIsSolved] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isResetOpen, setIsResetOpen] = useState(false);
    const auth = getAuth();

    

    useEffect(() => {
      const newGameLogic = new GameLogic(puzzleModel, (newPieces) => {
          setPieces(newPieces);
      });
      setGameLogic(newGameLogic);
  }, [puzzleModel]);

  useEffect(() => {
      puzzleModel.shufflePuzzle();
      setPieces([...puzzleModel.puzzle]);
  }, [puzzleModel]);

  useEffect(() => {
      if (isSolved) {
          setIsPopupOpen(true);
      }
  }, [isSolved]);

  useEffect(() => {
      setIsSolved(gameLogic?.isPuzzleSolved());
  }, [pieces, gameLogic]);

  useEffect(() => {
      const timer = setTimeout(() => {
          if (!isSolved) {
              setIsResetOpen(true);
          }
      }, 60000); // 1 minute
      return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = timeLeft > 0 && setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    if (timeLeft === 0) {
        // Logique à exécuter lorsque le timer atteint 0
        setIsResetOpen(true);
    }
    return () => clearTimeout(timer);
}, [timeLeft]);

const getTimerColor = () => {
  if (timeLeft <= 10) {
      return 'red';
  } else if (timeLeft <= 30) {
      return 'orange';
  }
  return 'green';
};


  const handleSelectPiece = (pieceId) => {
      gameLogic.selectPiece(pieceId);
      setSelectedPieceIds([...gameLogic.selectedPieces]);
  };
  

  const handleQuit = async () => {
      try {
          await signOut(auth);
      } catch (error) {
          console.error("Erreur lors de la déconnexion : ", error);
      }
  };



    return (
        <div>
             <div style={{
                color: getTimerColor(),
                fontSize: '1.5rem',
                fontWeight: 'bold',
                textAlign: 'center',
                margin: '10px 0',
                // Style amusant pour le timer
                fontFamily: '"Comic Sans MS", cursive, sans-serif'
            }}>
                Temps restant : {timeLeft} secondes
            </div>
        <div className="puzzle-board" style={{ position: 'relative' }}>
            {pieces.map(piece => (
                <PuzzlePiece
                    key={piece.id}
                    piece={piece}
                    isSelected={selectedPieceIds.includes(piece.id)}
                    onSelect={handleSelectPiece}
                
                />
            ))}
            
        </div>
         {/* {isSolved && <div className="solved-message">Félicitations ! Le puzzle est résolu.</div>}
      */}
            <Modal
        backdrop="opaque"
        isOpen={isResetOpen}
        onOpenChange={() => setIsPopupOpen(false)} // Fermer le popup en changeant l'état
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Félicitation!</ModalHeader>
              <ModalBody>
                <p>Vous êtes éligible pour remporter le prix </p>
                <p> Vous devez trouver les réponses aux questions suivantes</p>
              </ModalBody>
              <ModalFooter>

                <Question />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        backdrop="opaque"
        isOpen={isResetOpen}
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
                <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Réessayer?</ModalHeader>
              <ModalBody>
              <p>Temps écoulé </p>
                <p>Tentez votre chance de nouveau </p>
                
              </ModalBody>
              <ModalFooter>


          <Link to ="/puzzle">
          <Button
            className="bg-[#F9FAFA] shadow-lg shadow-green-500/20"
            onPress={() => {
              
              
            }}
          >
            Réessayer
          </Button>
          </Link>
          <Link to="/win" >
          <Button color="foreground" variant="light" onPress={handleQuit}>
            Quitter
          </Button>
          </Link>
          
          </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

        </div>




    );
};

export default PuzzleBoard;