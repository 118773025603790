import React, { useState, useEffect } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { collection, getDocs, query, where, doc, getDoc, setDoc } from "firebase/firestore";
import db from '../Firebase/firebase'; // Assurez-vous que db est correctement configuré pour Firestore
import "firebase/firestore";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { Link } from "react-router-dom";

export default function Question() {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const auth = getAuth();

// Utilisez onAuthStateChanged pour écouter les changements d'état de l'utilisateur
onAuthStateChanged(auth, (user) => {
  if (user) {
    // L'utilisateur est connecté, vous pouvez accéder à son ID
    const userID = user.uid;
    // Maintenant, vous pouvez utiliser userID dans votre handleSubmit
    handleSubmit(userID);
  } else {
    // L'utilisateur n'est pas connecté
  }
});

  useEffect(() => {
    // Récupérer les questions depuis la collection "Questions"
    const fetchQuestions = async () => {
      const querySnapshot = await getDocs(collection(db, "Questions"));
      const questionsData = [];
    
      querySnapshot.forEach((doc) => {
        const question = doc.data().question;
        const options = doc.data().options; // Récupérez le tableau d'options du document
    
        questionsData.push({ id: doc.id, question, options }); // Ajoutez les options aux données de la question
      });
    
      setQuestions(questionsData);
    };
    fetchQuestions(); 
  }, []);
  
  

  const handleAnswerSelection = (questionId, selectedOption) => {
    setSelectedAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      const questionIndex = updatedAnswers.findIndex((answer) => answer.questionId === questionId);
      if (questionIndex !== -1) {
        updatedAnswers[questionIndex] = { questionId, selectedOption };
      } else {
        updatedAnswers.push({ questionId, selectedOption });
      }
      return updatedAnswers;
    });
  };

const handleSubmit = async () => {
    // Assurez-vous que l'utilisateur est authentifié de manière anonyme
    const user = auth.currentUser;
    if (!user) {
      try {
        await signInAnonymously(auth);
      } catch (error) {
        console.error("Erreur lors de l'authentification anonyme : ", error);
        return;
      }
    }

    // Récupérez l'ID utilisateur anonyme
    const userID = user.uid;

    // Enregistrez les réponses dans la collection "Participants" avec l'ID utilisateur anonyme
    const participantDocRef = doc(db, "Participants", userID);

    try {
      // Vérifiez d'abord si le document existe
      const participantDocSnap = await getDoc(participantDocRef);

      if (participantDocSnap.exists()) {
        // Si le document du participant existe, mettez à jour les réponses
        await setDoc(participantDocRef, { reponses: selectedAnswers }, { merge: true });
      } else {
        // Si le document du participant n'existe pas, créez-le avec les réponses
        await setDoc(participantDocRef, { reponses: selectedAnswers });
      }

      // onOpenChange();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement des réponses : ", error);
    }
  };
  
  

  return (
    <>
      <Button onPress={onOpen} style={{ backgroundColor: "#048337",  color: "white" }}>
         Repondre
      </Button>
      <Modal
  backdrop="opaque"
  isOpen={isOpen}
  onOpenChange={onClose}
  radius="2xl"
  classNames={{
    body: "py-6",
    backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
    base: "border-[#292f46] bg-[#05445E] dark:bg-[#19172c] text-[#a8b0d3]",
    header: "border-b-[1px] border-[#292f46]",
    footer: "border-t-[1px] border-[#292f46]",
    closeButton: "hover:bg-white/5 active:bg-white/10",
  }}
>
  <ModalContent>
    {(onClose) => (
      <>
        <ModalHeader className="flex flex-col gap-1">Jeu quiz : Répondez par 'Vrai' ou 'Faux'</ModalHeader>
        <ModalBody>
          {questions.length === 0 ? (
            <p>Chargement des questions...</p>
          ) : (
            questions.map((q) => (
              <div key={q.id} className="mb-4">
                <p className="font-medium">{q.question}</p>
                <div className="flex mt-2">
                  {q.options.map((option) => (
                    <Button
                      key={option}
                      color={selectedAnswers.find((a) => a.questionId === q.id && a.selectedOption === option) ? "gradient" : "foreground"}
                      variant={selectedAnswers.find((a) => a.questionId === q.id && a.selectedOption === option) ? "filled" : "light"}
                      className={`mr-2 mb-2  ${
                        selectedAnswers.find((a) => a.questionId === q.id && a.selectedOption === option) ? "bg-[#048337]" : ""
                      }`}
                      onClick={() => handleAnswerSelection(q.id, option)}
                    >
                      {option}
                    </Button>
                  ))}
                </div>
              </div>
            ))
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="foreground" variant="light" onPress={onClose}>
            Fermer
          </Button>
          <Link to="/win">
          <Button
            className="bg-[#F9FAFA] shadow-lg shadow-indigo-500/20"
            onPress={() => {
              handleSubmit();
              
            }}
          >
            Soumettre
          </Button>
          </Link>
        </ModalFooter>
      </>
    )}
  </ModalContent>
</Modal>

    </>
  );
}
