import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardFooter, Divider, Link, Image, Button } from "@nextui-org/react";
import { getDoc, doc } from "firebase/firestore";
import db from '../Firebase/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { signOut, getAuth } from "firebase/auth";

export default function Win() {
  const [gameUrl, setGameUrl] = useState("");

  useEffect(() => {
    const auth = getAuth();
    signOut(auth);

    const fetchGameUrl = async () => {
      try {
        const docRef = doc(db, 'gameUrl', 'Oyx6sEx5boepyzaqn5Gr');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const gameUrlFromFirebase = data.gameUrl;
          setGameUrl(gameUrlFromFirebase);
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de gameUrl : ", error);
      }
    };

    fetchGameUrl();
  }, []);

  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(gameUrl)}`, '_blank');
  };

  const shareOnWhatsApp = () => {
    const whatsappMessage = encodeURIComponent(`Participez au jeu de la BOA pour gagner : ${gameUrl}`);
    window.open(`https://api.whatsapp.com/send?text=${whatsappMessage}`, '_blank');
  };

  const shareOnInstagram = () => {  
    // Créez le texte de partage pour Instagram
    const instagramCaption = `Participez au jeu de la BOA pour gagner : ${gameUrl} #VotreHashtag`;
    
    // Ouvrez une nouvelle fenêtre pour partager sur Instagram
    window.open(`https://www.instagram.com/?url=${encodeURIComponent(instagramCaption)}`, '_blank');
  };
  
  
  return (
    <div 
    className="min-h-screen flex items-center justify-center"
    style={{
      backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/wheeloffortune--boa.appspot.com/o/floue.png?alt=media&token=765631dc-f6a0-4284-a9f4-3b02c067d8d1')",
      backgroundPositionX: "center", // Centre horizontalement
      backgroundPositionY: "bottom",    // Déplacer vers le haut
      backgroundSize: "cover", // Cela effectuera un zoom arrière
      backgroundRepeat: "no-repeat",
      width: "100%", // Assurez-vous que la div occupe tout l'espace disponible
      height: "100vh", // Vous pouvez ajuster la hauteur selon vos besoins
      display: "flex",
      justifyContent: "center", // Centre horizontalement
      alignItems: "center", // Centre verticalement
    }}
    >
      <style>
        {`
          .custom-card {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
          }
          .centered-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          .card-footer {
            display: flex;
            justify-content: center;
            flex-wrap: wrap; /* Permet aux boutons de passer à la ligne sur les petits écrans */
          }
          .card-footer .button {
            flex: 1; /* Les boutons se répartissent également sur la largeur de l'écran */
            margin: 5px; /* Marge entre les boutons */
            max-width: 200px; /* Largeur maximale pour éviter le débordement sur les petits écrans */
          }
          
          /* Ajouter une classe pour gérer la largeur de la carte sur les petits écrans */
          @media screen and (max-width: 600px) {
            .custom-card {
              width: 100%;
            }
          }
        `}
      </style>
      <Card className="max-w-[800px] custom-card">
        <CardHeader className="flex gap-3">
          <Image
            alt="nextui logo"
            height={40}
            radius="sm"
            src="https://firebasestorage.googleapis.com/v0/b/wheeloffortune--boa.appspot.com/o/Logo1.jpg?alt=media&token=364c79aa-abdd-4d3b-a899-192141711577"
            width={40}
          />
          <div className="flex flex-col">
            <p className="text-md">BOA</p>
            <p className="text-small text-default-500">boaburkinafaso.com</p>
          </div>
        </CardHeader>
        <Divider />
        <CardBody className="centered-text">
          <h1 className="text-2xl font-semibold mb-2">Merci d'avoir participé</h1>
          <p className="text-gray-600 text-sm mb-4">N'oubliez pas de partager pour avoir plus de chance de gagner</p>
          <p className="text-xs text-gray-500">Les gagnants seront contactés par le numéro de téléphone renseigné</p>
        </CardBody>
        <Divider />
        <CardFooter className="card-footer">
          <Link>
            <Button
              onClick={shareOnFacebook}
              className="bg-[#F9FAFA] shadow-lg shadow-indigo-500/20 button"
            >
              Partager sur <FontAwesomeIcon icon={faFacebook} className="mr-2" bounce />
            </Button>
          </Link>
          <Link>
            <Button
              onClick={shareOnWhatsApp}
              className="bg-[#F9FAFA] shadow-lg shadow-indigo-500/20 button"
            >
              Partager sur <FontAwesomeIcon icon={faWhatsapp} className="mr-2" bounce />
            </Button>
          </Link>
          <Link>
            <Button
              onClick={shareOnInstagram}
              className="bg-[#F9FAFA] shadow-lg shadow-indigo-500/20 button"
            >
              Partager sur <FontAwesomeIcon icon={faInstagram} className="mr-2" bounce />
            </Button>
          </Link>
        </CardFooter>
      </Card>
    </div>
  );
}
  