import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Acceuil from "./Pages/accueil";
import Form from "./Pages/form";
import Win from './Pages/win';
import Puzzle from './Pages/Puzzle';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>

          <Route path="/form" element={<Form />} />
          <Route path="/" element={<Acceuil />} />
          <Route path="/puzzle" element={<Puzzle />} />
          <Route path="/win" element={<Win />} />
          
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;