

    

class PuzzleModel {
    constructor(puzzleSize, imageUrls, onPiecesUpdate) {
        this.puzzleSize = puzzleSize;
        // this.initialPositions = {};
        // this.puzzle = this.initializePuzzle(imageUrls);
        this.puzzle = this.initializePuzzle(puzzleSize, imageUrls);
        this.onPiecesUpdate = onPiecesUpdate; // Fonction de rappel pour mettre à jour l'état dans PuzzleBoard
    }

    initializePuzzle(puzzleSize, imageUrls) {
        
        let puzzle = [];
        const pieceSize = 100; // Taille de chaque pièce en pixels

        for (let i = 0; i < this.puzzleSize * this.puzzleSize; i++) {
            const x = (i % this.puzzleSize) * pieceSize;
            const y = Math.floor(i / this.puzzleSize) * pieceSize;

           // this.initialPositions[i] = { x, y };

            puzzle.push({
                id: i,
                image: imageUrls[i],
                x: x,
                y: y,
                correctPosition: i,
                currentPosition: i,
            });
        }
        console.log("Initialisation des pièces :");
        puzzle.forEach(piece => {
            console.log(`id: ${piece.id}, currentPosition: ${piece.currentPosition}, correctPosition: ${piece.correctPosition}`);
        });
        

        return puzzle;
    }

    getInitialPosition(id) {
        return this.initialPositions[id];
    }

    shufflePuzzle() {
        let shuffledIndices = [];
    
        // Création d'un tableau d'indices
        for (let i = 0; i < this.puzzleSize * this.puzzleSize; i++) {
            shuffledIndices.push(i);
        }
    
        // Mélanger les indices
        for (let i = shuffledIndices.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledIndices[i], shuffledIndices[j]] = [shuffledIndices[j], shuffledIndices[i]];
        }
    
         // Réaffecter les positions mélangées aux pièces
    this.puzzle.forEach((piece, index) => {
        const shuffledIndex = shuffledIndices[index];
        const x = (shuffledIndex % this.puzzleSize) * 100;
        const y = Math.floor(shuffledIndex / this.puzzleSize) * 100;
        piece.x = x;
        piece.y = y;
        piece.currentPosition = shuffledIndex;
    });
    
        console.log("Après mélange des pièces :");
        this.puzzle.forEach(piece => {
            console.log(`id: ${piece.id}, currentPosition: ${piece.currentPosition}, correctPosition: ${piece.correctPosition}`);
        });
    }
    
    
    


    exchangePieces(pieceId1, pieceId2) {
        const piece1 = this.puzzle.find(piece => piece.id === pieceId1);
        const piece2 = this.puzzle.find(piece => piece.id === pieceId2);
        
        if (piece1 && piece2) {
           [piece1.x, piece2.x] = [piece2.x, piece1.x];
            [piece1.y, piece2.y] = [piece2.y, piece1.y];
            [piece1.currentPosition, piece2.currentPosition] = [piece2.currentPosition, piece1.currentPosition];
            
            // Échanger uniquement currentPosition
           
         console.log(`Positions échangées entre les pièces ${pieceId1} et ${pieceId2}`);
        }
        console.log(this.puzzle.map(p => `id: ${p.id}, x: ${p.x}, y: ${p.y}`).join('\n'));
        console.log(`Après échange des pièces ${pieceId1} et ${pieceId2} :`);
        this.puzzle.forEach(piece => {
            console.log(`id: ${piece.id}, currentPosition: ${piece.currentPosition}, correctPosition: ${piece.correctPosition}`);
        });

        if (this.onPiecesUpdate) {
            this.onPiecesUpdate([...this.puzzle]);
        }
        
    }
    

    isSolved() {
        return this.puzzle.every(piece => piece.currentPosition === piece.correctPosition);
    }
    
    
    

    movePiece(pieceId) {
        const emptyIndex = this.puzzle.findIndex(piece => piece.id === -1); // Trouver l'index de l'espace vide
        const pieceIndex = this.puzzle.findIndex(piece => piece.id === pieceId);
    
        if (this.isMovable(emptyIndex, pieceIndex)) {
            // Échanger la pièce et l'espace vide
            [this.puzzle[emptyIndex], this.puzzle[pieceIndex]] = [this.puzzle[pieceIndex], this.puzzle[emptyIndex]];
            // Mettre à jour les positions actuelles
            this.puzzle[emptyIndex].currentPosition = emptyIndex;
            this.puzzle[pieceIndex].currentPosition = pieceIndex;
        }
    }
    
    isMovable(emptyIndex, pieceIndex) {
        // Détermine si une pièce peut être déplacée à l'emplacement vide
        // La logique dépend de la disposition de votre puzzle (par exemple, puzzle linéaire, grille 2D, etc.)
        // Par exemple, pour une grille 2D, vérifiez si la pièce est adjacente à l'emplacement vide
        const size = Math.sqrt(this.puzzle.length); // Taille de la grille (par exemple, 3 pour un puzzle 3x3)
        const emptyRow = Math.floor(emptyIndex / size);
        const emptyCol = emptyIndex % size;
        const pieceRow = Math.floor(pieceIndex / size);
        const pieceCol = pieceIndex % size;
    
        // Vérifier si la pièce est sur la même ligne ou colonne et adjacente à l'espace vide
        return (emptyRow === pieceRow && Math.abs(emptyCol - pieceCol) === 1) ||
               (emptyCol === pieceCol && Math.abs(emptyRow - pieceRow) === 1);
    }
    
    findPieceAt(x, y) {
        return this.puzzle.find(piece => {
            return this.isWithinPiece(x, y, piece);
        });
    }
    
    
    isWithinPiece(x, y, piece) {
        const pieceX = piece.x;
        const pieceY = piece.y;
        const pieceWidth = 100; // Assumer une largeur de pièce de 100px
        const pieceHeight = 100; // Assumer une hauteur de pièce de 100px
        const tolerance = 10; // Ajouter une tolérance pour l'élasticité
    
        return x >= pieceX - tolerance && x <= pieceX + pieceWidth + tolerance && 
               y >= pieceY - tolerance && y <= pieceY + pieceHeight + tolerance;
    }
    
    

    // Autres méthodes et logiques nécessaires pour le jeu
}

export default PuzzleModel;
