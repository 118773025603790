import React, { useState,useEffect } from "react";
import { Textarea, Button, Checkbox, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import confetti from 'canvas-confetti';
import { useNavigate } from "react-router-dom";
import db from '../Firebase/firebase'; // Importez votre instance Firebase
import { collection, setDoc, query, where, getDocs,getDoc, doc } from 'firebase/firestore';
import { getAuth, signInAnonymously } from "firebase/auth";


export default function Forme() {
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    acceptTerms: false,
  });

  const [termesConditions, setTermesConditions] = useState("");

  const checkPhoneNumberExists = async (phoneNumber) => {
    const participantsRef = collection(db, 'Participants');
    const q = query(participantsRef, where('phoneNumber', '==', phoneNumber));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Retourne true si le numéro existe déjà, sinon false.
  };

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const updatedValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  useEffect(() => {
    // Fonction pour récupérer les termes et conditions depuis Firebase
    const fetchTermesConditions = async () => {
      try {
        const docRef = doc(db, "Termes", "Termes"); // Référence au document "Termes" avec l'ID "Termes"
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const termes = [];

        // Parcourir les champs du document et les ajouter à la liste "termes"
        for (let i = 1; data[`termes${i}`]; i++) {
          termes.push(data[`termes${i}`]);
        }

        setTermesConditions(termes.map((terme, index) => (
          <p key={index}> <br />{terme}</p>
        )));
      
        } else {
          console.log("Le document Termes n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des termes et conditions :", error);
      }
    };

    // Appel de la fonction pour récupérer les termes et conditions
    fetchTermesConditions();
  }, []); // Utilisation d'une dépendance vide pour que cela se produise une seule fois


  const handleSubmit = async (event) => {
    event.preventDefault();


    if (!formData.firstName || !formData.phoneNumber) {
      setWarning("Veuillez remplir tous les champs obligatoires.");
      return;
    }
    if (!formData.acceptTerms) {
      setWarning("Veuillez accepter les conditions générales de participation au jeu.");
      return;
    }

    if (formData.phoneNumber.length !== 8 || !/^\d+$/.test(formData.phoneNumber.slice(4))) {
      setWarning("Le numéro de téléphone doit contenir 8 chiffres.");
      return;
    }

    const phoneNumberExists = await checkPhoneNumberExists(formData.phoneNumber);

    if (phoneNumberExists) {
      setWarning('Numéro déjà enregistré.');
      return;
    }


    // Clear any previous warnings
    setWarning("");


    // Ajouter un nouveau participant à Firebase
    try {
      // Activer l'authentification anonyme
      await signInAnonymously(auth);

      // Obtenir l'utilisateur actuellement authentifié
      const user = auth.currentUser;

      if (user) {
        // Utilisez l'ID d'authentification comme ID du document
        const userID = user.uid;

        // Ajoutez le participant à Firebase
        const participantsRef = collection(db, 'Participants');
        const docData = {
          nom: formData.firstName,
          
          téléphone: formData.phoneNumber,
          date: new Date().toISOString(),
          
        };

        await setDoc(doc(participantsRef, userID), docData);

        // Redirigez l'utilisateur vers la page de la roue
        setIsSubmitButtonEnabled(true);
        navigate('/puzzle');
      } else {
        // L'utilisateur n'est pas authentifié
        setWarning('L\'utilisateur n\'est pas authentifié.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'authentification anonyme ou de l\'ajout du participant à Firebase :', error);
      setWarning('Une erreur est survenue lors de la soumission.');
    }

    // Perform submission logic here
    console.log(formData);
    setIsSubmitButtonEnabled(true);
    navigate("/puzzle");
  };
  const handleConfetti = () => {
    confetti({});
  };
  const [warning, setWarning] = useState("");

  const auth = getAuth();

  return (
    <form onSubmit={handleSubmit} className="w-full grid grid-cols-12 gap-4">
      <Textarea
        name="firstName"
        variant="bordered"
        label="Nom et Prénom"
        labelPlacement="outside"
        placeholder="Entrez votre nom suivit de votre prénom"
        className="col-span-12 md:col-span-6 mb-6 md:mb-0"
        value={formData.firstName}
        onChange={handleInputChange}
        isRequired
        required
      />
      
      <Textarea
        name="phoneNumber"
        variant="bordered"
        label="Numéro de téléphone"
        labelPlacement="outside"
        placeholder="Entrez votre numéro de téléphone"
        className="col-span-12 md:col-span-6 mb-6 md:mb-0"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        isRequired
        pattern="[0-9]{8}"
        inputMode="numeric"
        required
        
      />
      <div className="col-span-12">

        <Checkbox
          type="checkbox"
          name="acceptTerms"
          checked={formData.acceptTerms}
          onChange={handleInputChange}>J'accepte les conditions générales de participation au jeu</Checkbox>
      </div>
      <Button
        type="submit"
        ref={Button}
        disableRipple
        size="lg"
        // onPress={handleConfetti}
        radius="full"
        className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg"
      // disabled={!isSubmitButtonEnabled}
      >Soumettre</Button>
      <div className="col-span-12 flex justify-center items-end pb-8">
        <Button onPress={onOpen}>Termes et conditions</Button>
      </div>
      <div className="col-span-12 flex justify-center items-end pb-8" >
        {warning && <p className="text-red-500 text-sm">{warning}</p>}
      </div>

      <Modal
        backdrop="opaque"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        style={{
          maxHeight: "80vh",
          overflowY: "auto",
        }}
        motionProps={{
          variants: {
            enter: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.3,
                ease: "easeOut",
              },
            },
            exit: {
              y: -20,
              opacity: 0,
              transition: {
                duration: 0.2,
                ease: "easeIn",
              },
            },
          }
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Termes et conditions</ModalHeader>
              <ModalBody>
                <p>{termesConditions}</p> {/* Affichage des termes et conditions depuis l'état */}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Retour
                </Button>

              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </form>

  );
}